<div class="d-flex pb-3 mb-3 tr-row align-items-center border-bottom">
    <span class="num">#{{ranking}}</span>
    <div class="mr-auto pr-3">
        <a href="post-details.html">
            <h2 class="text-black fs-14 font-w500">{{restaurant.name}}</h2>
        </a>
        <span class="text-black font-w600 d-inline-block mr-3">{{restaurant.numOrders}} </span> <!--<span
            class="fs-14">commandes (+2.5%)</span>-->
    </div>
    <!--<img src="https://fast-order.fr/uploads/pictures/restaurants/65/e4qst9wys.jpg" alt="">-->
</div>