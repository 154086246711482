import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IStatsRangeBody, IEmployeeOrderStat, IRestaurantRankingBody, IRestaurantOrderStat, IExpenseTotalStat, IOrderCountByDayStat, ITotalAmountByDayStat, IRefundTotalStat, ICompanyBudgetByMonthStat } from '../../interfaces';
import { ITotalAmountByMonthStat } from '../../interfaces/i-total-amount-by-day-stat';
import { BaseCRUDService } from '../base.crud.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "stats"
  }

  /*
    * Classements des employés par nombre de commandes.
  */
  employeeOrders(body:IStatsRangeBody):Observable<Array<IEmployeeOrderStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/employees/orders`,body) as Observable<Array<IEmployeeOrderStat>>;
  }

  /*
    * Classements des restaurants par nombre de commandes.
  */
  restaurantsOrders(body:IRestaurantRankingBody):Observable<Array<IRestaurantOrderStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/restaurants/orders`,body) as Observable<Array<IRestaurantOrderStat>>;
  }

  /*
    * Dépenses sur une période par entreprise
  */
  orderExpenseTotal(body:IStatsRangeBody):Observable<IExpenseTotalStat>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/expenses/total`,body) as Observable<IExpenseTotalStat>;
  }

  /*
    * Nombre de commande entre deux dates.
  */
  orderByDateRange(body:IStatsRangeBody):Observable<Array<IOrderCountByDayStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/orders/count`,body) as Observable<Array<IOrderCountByDayStat>>;
  }

  /*
    * Dépenses totale par jour sur une période
  */
  totalExpenseByDay(body:IStatsRangeBody):Observable<Array<ITotalAmountByDayStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/orders/amount`,body) as Observable<Array<ITotalAmountByDayStat>>;
  }

  /*
    * Dépenses totale par jour sur une période
  */
  totalExpenseByMonth(body:IStatsRangeBody):Observable<Array<ITotalAmountByMonthStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/expenses/month`,body) as Observable<Array<ITotalAmountByMonthStat>>;
  }
  
  /*
    * Montant des rechrgements employées.
  */
  totalRefundByDay(body:IStatsRangeBody):Observable<IRefundTotalStat>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/customers/refund`,body);
  }

  /*
    * Budget mensuel entreprise
  */
  totalCompanyBudget(body:IStatsRangeBody):Observable<Array<ICompanyBudgetByMonthStat>>{
    return this.http.post(`${environment.API_URL}/${this.controllerName}/company/budget`,body) as Observable<Array<ICompanyBudgetByMonthStat>>;
  }
}
