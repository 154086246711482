import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['company']);

const routes: Routes = [
  {path: '', loadChildren : () => import("./pages/default-login/default-login.module").then((m) => m.DefaultLoginModule), canActivate: [AngularFireAuthGuard],data : { authGuardPipe:redirectLoggedInToItems }},
  {path : "", loadChildren : () => import("./pages/app-layout/app-layout.module").then((m) => m.AppLayoutModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }