import { ValidateNested } from "class-validator";
import { ProfileDTO } from "./profile/profile.dto";

export enum UserRole {
    PROFESSIONNAL='professionnal',
    OPERATIONNAL='operationnal',
    ADMIN='admin',
    EMPLOYEE='employee'
}

export class UserDTO{
    id!: number;
    // The user role
    role!:UserRole;
    // Firebase uid associated to this account
    uid!: string;

    company : any = {
        id : null,
    };

    group:any = {
        id : null
    }

    @ValidateNested()
    profile:ProfileDTO = new ProfileDTO();
}