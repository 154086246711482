import { AfterViewInit, HostListener, Input, ViewChild } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseCRUDService } from 'src/app/shared/services/base.crud.service';
import { IFilterConfig } from '../../interfaces/filter-config.interface';
import { NotificationService } from '../../services/notifier/notification.service';
import { AppUtils } from '../../utils/app.utils';

export interface IFODatatableConfig {
  rowHasDetails: boolean
  editUrl: string
  deletableRow: boolean
}

@Component({
  selector: 'fo-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class FODatatableComponent implements OnInit, AfterViewInit {
  @ViewChild('actionTpl') actionTpl!: TemplateRef<any>
  @ViewChild("multipleTpl") multipleTpl!: TemplateRef<any>;
  @ViewChild("stockTpl") stockTpl!: TemplateRef<any>;
  @ViewChild("deleteTpl") deleteTpl!: TemplateRef<any>;
  @ViewChild("groupDisplay") groupDisplayTpl!: TemplateRef<any>;

  @Input() columns: any[] = [];
  @Input() perPage: number = 30;
  @Input() service!: BaseCRUDService;
  @Input() action: boolean = true;
  @Input() editUrl: string = "";
  @Input() mapFunction: any = () => { };
  @Input() templateName: string = "actionTpl";
  @Input() getFilters!: (value: string, dates: Date[]) => {}
  @Input() qb!: RequestQueryBuilder;
  @Input() filterConfig: IFilterConfig = { text: false, date: false };
  @Input() rowHasDetails: boolean = false;
  @Input() deletableRow: boolean = false;
  isLoading: boolean = false;
  rows: any[] = [];
  page: any = {
    size: 30,
    totalPages: 1,
    pageNumber: 1,
    totalElements: 1
  }
  datas: any;
  paging: boolean = false;
  modalRef: any;
  currentRow: any;
  dateRange: { start: Date, end: Date } = { start: null, end: null };
  private textFilter!: string;
  public details: Promise<string>

  @HostListener("window:DATA_CHANGE") onDataChange() {
    this.getPageOfData(this.page.pageNumber);
  }

  constructor(private _router: Router, private _bsModalService: BsModalService, private notificationService: NotificationService) { }

  displayEdit() {
    return this.columns.some((col): any => col.editable)
  }

  ngAfterViewInit(): void {
    this.columns.push({ name: "Actions", cellTemplate: (this as any)[this.templateName], prop: 'id', width: "auto" });

    let multiOpt = this.columns.find((elem) => { return elem.prop == "isMulti" });
    if (multiOpt) multiOpt.cellTemplate = this.multipleTpl;

    let stockOpt = this.columns.find((elem) => { return elem.prop == "inStock" });
    if (stockOpt) stockOpt.cellTemplate = this.stockTpl;

    let groupOpt = this.columns.find((elem) => elem.prop == "group.id");
    if (groupOpt) groupOpt.cellTemplate = this.groupDisplayTpl;

    this.columns = [...this.columns];
  }

  ngOnInit(): void {
    this.getPageOfData(1);

    if (this.columns.some(col => col.prop === 'orderNum')) {
      this.notificationService.orderEvt.subscribe(() => {
        this.getPageOfData(this.page.pageNumber)
      })
    }
  }

  getNameFromId(id: number) {
    return this.columns.find((elem: any) => elem.prop == "group.id").editable?.choices?.find((gr: any) => id == gr.value)?.label || "";
  }

  openModal(template: TemplateRef<any>, row: any) {
    this.currentRow = row;
    this.details = this.service.getDetails(this.currentRow)
    this.modalRef = this._bsModalService.show(template)
  }

  getModelfromString(prop: string) {
    return (prop == 'group.id' && !this.currentRow.group) ? null : eval(`this.currentRow.${prop}`);
  }

  modelFromString(prop: string, value: any) {
    (prop == 'group.id' && !this.currentRow.group) ? eval(`this.currentRow.group = { id : ${value}};`) : eval(`this.currentRow.${prop} = '${value}'`);
  }

  update(row: any, data: any = null) {
    let updateData: any = { ...row };

    if (Number.isInteger(updateData.id)) {
      this.modalRef?.hide();
      this.service.updateOne(updateData.id, updateData).subscribe({
        next: (data) => {
          AppUtils.toast_success("Mise à jour", "la mise à jour a été effectuée avec succès");
          window.dispatchEvent(new CustomEvent("GROUP_UPDATE", { bubbles: true }));
        },
        error: (err) => AppUtils.toast_error("Mise à jour", "impossible de mettre à jour l'élément")
      })
    } else {
      this.modalRef?.hide();
      this.service.updateByUUID(updateData.uuid, updateData).subscribe({
        next: (data) => {
          AppUtils.toast_success("Mise à jour", "la mise à jour a été effectuée avec succès");
          window.dispatchEvent(new CustomEvent("GROUP_UPDATE", { bubbles: true }));
        },
        error: (err) => AppUtils.toast_error("Mise à jour", "impossible de mettre à jour l'élément")
      })
    }
  }

  goto(id: string) {
    this._router.navigate([`${this.editUrl}/${id}`]);
  }

  delete(id: string) {
    this.service.delete(id).subscribe({
      next: (data) => {
        let ob = this.rows.find((elem) => elem.id == id);
        this.getPageOfData(this.page.pageNumber);
        AppUtils.toast_success("Suppression", "la suppression a été effectuée avec succès")
      },
      error: (err) => AppUtils.toast_error("Suppression", "la suppresion est impossible")
    })
  }


  applyFilter(textVal: string, dates: Date[] = []) {
    this.textFilter = textVal;
    this.qb.search(this.getFilters(textVal, dates));
    this.getPageOfData(1);
  }

  /**
   * Filter text data with input value
   */
  filterDataTable(evt: any): void {
    this.applyFilter(String(evt.target.value).toLowerCase(), [this.dateRange.start, this.dateRange.end])
  }

  getPageOfData(page: number) {
    this.isLoading = true;

    if (!this.qb)
      this.qb = RequestQueryBuilder.create();

    this.qb.setPage(page);
    this.qb.setLimit(this.perPage);

    this.service.getDataWithQb(this.qb.query())
      .subscribe({
        next: (observer: any) => {
          console.log(observer)
          if (observer.pageCount) {
            this.paging = true;
            this.page.size = this.perPage;
            this.page.totalPages = observer.pageCount;
            this.page.pageNumber = observer.page;
            this.page.totalElements = observer.total;
            this.datas = observer.data;
          } else {
            console.log("paging false")
            this.paging = false;
            this.datas = observer.data;
          }

          this.rows = [];
          if (this.datas) {
            this.initRows()
          }
        },
        complete: () => this.isLoading = false,
        error: () => this.isLoading = false
      })
  }

  /**
   * Initialize the rows to their initial value
   */
  initRows(): void {
    this.rows = this.data;
  }

  get data() {
    return this.datas.map(this.mapFunction)
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset + 1;
    this.getPageOfData(this.page.pageNumber);
  }

  filterByDate() {
    this.applyFilter(this.textFilter, [this.dateRange.start, this.dateRange.end])
  }
}
