<!--**********************************
            Nav header start
        ***********************************-->
<div class="nav-header">
  <a [routerLink]="['/']" class="brand-logo">
    <img class="logo-abbr" src="assets/img/fastorder/fast_order_logo.png" width="64" />
    <div class="brand-title">FastOrder</div>
  </a>
  <div class="nav-control" (click)="activeMenu()">
    <div class="hamburger" [ngClass]="{'is-active':!isActive}">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </div>
  </div>
</div>
<!--**********************************
          Nav header end
      ***********************************-->
<!--**********************************
            Header start
        ***********************************-->
<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
          <div class="dashboard_bar">
            Fastoffice
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>