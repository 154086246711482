import Swal from 'sweetalert2';

export class AppUtils{

    public static  config:any = {
        toast: true,
        position: 'center',
        showConfirmButton: true,
        timer: 2000,
        timerProgressBar: true
      };

    static toast_error(title:string,message:string){
        Swal.mixin(AppUtils.config).fire(title,message,"error");
    }

    static toast_success(title:string,message:string){
        Swal.mixin(AppUtils.config).fire(title,message,"success");
    }

    static parseErrors(errs:any[]) {
      let errorHolder:any[] = [];
      errs.forEach(element => {
        for (let st in element.constraints) {
          errorHolder.push(element.constraints[st]);
        }
        errorHolder = errorHolder.concat(this.parseErrors(element.children));
      });
      return errorHolder;
    }

}