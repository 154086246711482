import { IsBoolean, IsEmail, IsNotEmpty, IsPhoneNumber, Min } from "class-validator";
import { DishPhotoDto } from "../../photo/dish-photo-dto";

export class ProfileDTO
{
  id!:number;
  @IsNotEmpty({ message : "Définir le prénom"})
  firstname!:string;

  @IsNotEmpty({ message : "Définir le nom"})
  lastname!:string;

  @IsBoolean({message : "Définir le sexe"})
  gender: boolean = false;

  @Min(16,{message : "l'age doit être de 16ans minimum" })
  age!: number;

  photo: DishPhotoDto = new DishPhotoDto("");

  @IsPhoneNumber("SN",{message : "Le numéro n'est pas valide"})
  tel: string="";

  @IsEmail({},{message : "Définir une addresse mail valide"})
  email!:string;
}