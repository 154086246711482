import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TerminalService } from '../terminal/terminal.service';
import OrderHistoryDTO from './dto/order.history.dto';
import { BaseCRUDService, UserService } from '..';
import { take } from 'rxjs/operators';
import { DateUtils } from '../../utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseCRUDService {

  private orderListChange: EventEmitter<void> = new EventEmitter();

  constructor(private terminalService: TerminalService, private userService: UserService, http: HttpClient) {
    super(http)
    this.controllerName = "orders";
  }

  private async getOrdersByStatus(status: string) {
    // Get the terminal associated with the restaurant
    let user = await this.userService.firebaseUser() as any;
    let terminal: any = await this.terminalService.getTerminalByUuid(user.uid);
    return (terminal && terminal.termRestaurant) ? this.http.get(`${environment.API_URL}/orders/restaurants/${terminal.termRestaurant.id}/${status}`) : new Observable();
  }

  history(request: OrderHistoryDTO): Observable<any> {
    return this.http.post(`${environment.API_URL}/orders/history`, request);
  }

  emitOrderChangeEvent() {
    this.orderListChange.emit();
  }

  getOrderListChangeEvent() {
    return this.orderListChange;
  }

  restoreOrder(orderId: string) {
    return this.http.put(`${environment.API_URL}/orders/restore`, { id: orderId });
  }

  getPendingOrders() {
    return this.getOrdersByStatus('pending');
  }

  getCompletedOrders() {
    return this.getOrdersByStatus('completed');
  }

  getCanceledOrders() {
    return this.getOrdersByStatus('canceled');
  }

  getProcessedOrders() {
    return this.getOrdersByStatus('processed');
  }

  processOrder(orderId: string) {
    return this.http.put(`${environment.API_URL}/orders/process`, { id: orderId });
  }

  cancelOrder(orderId: string) {
    return this.http.put(`${environment.API_URL}/orders/cancel`, { id: orderId });
  }

  completeOrder(orderId: string) {
    return this.http.put(`${environment.API_URL}/orders/complete`, { id: orderId });
  }

  getOrderDetail(orderId: string) {
    return this.http.get(environment.API_URL + '/orders/' + orderId);
  }

  getOrderTicket(orderId: string) {
    return this.http.get(environment.API_URL + '/orders/' + orderId + '/ticket/data');
  }

  private extractDishDetails(supplements) {    
    let str = "";
    
    if (supplements) {
        for (let [cat, s] of Object.entries(supplements))
        {
            str += `<h6>${cat}</h6>`;
            str += "<ul>";
            
            if (Array.isArray(s)) {
               s.forEach (sup => {
                str += `<li>${sup.name}</li>`;
               });
            } 
            else {
                str += `<li>${(s as any).name}</li>`;
            }

            str += "</ul>";
        }
    }
    return str;
  }

  private getOrderInfo(info)
  {
    let str = "";
    str += `<strong>Nom du restaurant:</strong> ${info.restaurant.name}<br>`;
    str += `<strong>Tel du restaurant:</strong> ${info.restaurant.tel}<br>`;
    str += `<strong>Commande:</strong>  ${info.orderNum}<br>`;
    str += `<strong>Date de la commande:</strong> ${DateUtils.getFormatedDate(Number(info.timestamp))}<br>`;
    str += `<strong>Nom client:</strong> ${info.client}<br>`;
    str += `<strong>Téléphone client:</strong> ${info.tel}<br>`;
    str += `<strong>Méthode de paiement:</strong> ${info.method}<br>`;
    str += `<strong>Méthode de réception:</strong> ${info.type}<br>`;
    str += `<strong>Total:</strong> ${info.total} ${info.currency}<br>`;
    str += "<hr>";

    for (let dish of info.dishes) {
        str += `<h4>Plat ${dish.title} - Prix: ${dish.price}</h4>`;
        if (dish.selectedOptions && Object.keys(dish.selectedOptions).length !== 0) {
          str += this.extractDishDetails(dish.selectedOptions);
        }

        if (dish.selectedSupplements && Object.keys(dish.selectedSupplements).length !== 0) {
          str += this.extractDishDetails(dish.selectedSupplements);
        }
    }

    return str;
  }

  getDetails(rowData: any): Promise<string> {
      return new Promise<string>((resolve, reject) => {
        this.getOrderDetail(rowData._id)
          .pipe(take(1))
          .subscribe((details: any) => {
            console.log(details)
            resolve(this.getOrderInfo(details));
          }, (error: any) => reject(error));
      })
  }
}
