import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoService{

  constructor(private _http:HttpClient) { 
  }

  delete(idPhoto:number,type:string){
    return this._http.delete(`${environment.API_URL}/${type}/photo/${idPhoto}`)
  }
  
  getByName(id:number,image:any,temp:boolean=false):string{
    let img = (image.name) ? image.name : 'noimage.jpeg';
    return (id) ? `${environment.API_URL}/uploads/pictures/restaurants/dishes/${id}/${img}` : `${environment.API_URL}/uploads/pictures/restaurants/dishes/${img}`;
  }

  getRestaurantLogo(id:number,imageName:any):string{
    let img = (imageName) ? imageName : 'noimage.png';
    return (imageName) ? `${environment.API_URL}/uploads/pictures/restaurants/${id}/${img}` : `${environment.API_URL}/uploads/pictures/restaurants/${img}`;
  }

  static getCoverImg(item:any,type:string): string {
    let image = (item.photos[0]) ? item.photos[0].name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/${type}/${item.id}/${image}`;
  }

  static getImgLink(item:any,image:string,type:string): string {
    return `${environment.API_URL}/uploads/pictures/${type}/${image}`;
  }
}
