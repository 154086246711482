import { Component, Input, OnInit } from '@angular/core';
import { IRestaurantOrderStat } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-restaurant-trending-list-item',
  templateUrl: './restaurant-trending-list-item.component.html',
  styleUrls: ['./restaurant-trending-list-item.component.scss']
})
export class RestaurantTrendingListItemComponent implements OnInit {
  @Input() restaurant!:IRestaurantOrderStat;
  @Input() ranking! : number
  constructor() { }

  ngOnInit(): void {
  }

}
