import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fo-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss']
})
export class CardTitleComponent implements OnInit {
  @Input() title! : string;
  @Input() subTitle!:string;
  constructor() { }

  ngOnInit(): void {
  }

}
