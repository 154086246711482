 
        <div class="modal-header">
          <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
            {{title}}</h3>
          <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <p>{{message}} </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" (click)="modalRef.hide()">Annuler</button>
          <button type="button" (click)="delete()" class="btn btn-primary shadow-none"
            (click)="modalRef.hide()">Valider</button>
        </div>