import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PayexpresseService } from '../../services';

@Component({
  selector: 'fo-payexpess',
  templateUrl: './payexpess.component.html',
  styleUrls: ['./payexpess.component.scss']
})
export class PayexpessComponent implements OnInit {

  @Input() company:any;
  @Input() total!:number;
  @ViewChild('frame') myFrame!: ElementRef;
  companyName!:string;
  order:any;
  token!:string;
  orderID!:string;
  constructor(private peService:PayexpresseService) { }

  ngOnInit(): void {
  }

  @HostListener("window:UPDATE_PAYEXPRESS")
  ngOnChanges(){
    this.peService.getPaymentToken({
      "item_name": `Commande fastorder ${this.company.name}`,
       "item_price": this.total,
       "custom_field" : {companyId : this.company.id},
       "command_name": ` Moussa Sissoko Paiement via payexpresse`,
       "currency": "XOF"
    }).subscribe( async (payData:any) => {
      this.token = payData.token;
      this.orderID = payData.randomTransactionId;

      window.addEventListener("message", async (event:any) => {
        let data = JSON.parse(event.data);
        if(!data.is_completed && (data.type && data.type == "close_iframe")){
        }else if(data.is_completed && (data.type && data.type == "close_iframe")){
        }
      }, false)
      
    },console.error)
  }


}
