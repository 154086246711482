import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class BaseCategoryCrudService extends BaseCRUDService{

  constructor(_httpClient:HttpClient) { 
    super(_httpClient);
  }

  getOptionFromMenuID(menuID:number){
    return this.http.get(`${environment.API_URL}/${this.controllerName}/menu/${menuID}`);
  }
}
