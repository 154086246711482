import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class SupplementService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
      super(_http);
      this.controllerName = "supplements";
  }
}
