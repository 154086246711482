import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class TagService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http)
    this.controllerName = "tag";
  }

  assignTagToDishes(body:any){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/assign`, body)
  }
}
