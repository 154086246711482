import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AclService, TerminalService, UserService } from './shared/services';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import firebase from 'firebase/compat/app';
import { AppUtils } from './shared/utils/app.utils';
import { Router } from '@angular/router';
import { UserRole } from './shared/dto/user/user.dto';
import { Socket } from 'ngx-socket-io';
import { NotificationService } from './shared/services/notifier/notification.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMapTo } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private readonly permissionsService: NgxPermissionsService,
              private _roleService:NgxRolesService,
              private afa: AngularFireAuth, 
              private userService: UserService,
              private readonly aclService: AclService,
              private router: Router, 
              private socket: Socket,
              private readonly notificationService: NotificationService,
              private afMessaging: AngularFireMessaging,
              private terminalService: TerminalService) {

  }

  ngOnInit(){
    this.socket.connect();

    // Subscribe on the auth state service to load the user roles and permissions
    this.afa.authState.subscribe((user:any) => {
      this.userService.getDataWithQb(RequestQueryBuilder.create()
                      .setFilter({ field: 'uid', operator: '$eq', value: user?.uid }).query())
                      .subscribe({ 
                        next: (data:any) => {
                          const user = data[0];
                          if (user) {
                            this.aclService.getAcl().subscribe({
                              next: async (aclList:any) => {
                                // Load user access control list and role
                                this.permissionsService.loadPermissions([...aclList, user.role])                                
                                // subscribe to orders event if user has access to the orders screen and show notification on new order event
                                if (await this.permissionsService.hasPermission('orders')) {
                                  this.socket.on('order/new/event', (orderEvt: any) => {
                                    this.notificationService.showOrderStatusUpdateNotification(orderEvt);
                                  });

                                  this.requestPermission();
                                } 

                                // Redirect the user according to its role
                                switch (user.role) {
                                  case UserRole.ADMIN: this.router.navigate(['dashboard']); break;
                                  case UserRole.PROFESSIONNAL: this.router.navigate(['company']); break;
                                  case UserRole.OPERATIONNAL: this.router.navigate(['orders']); break;
                                }
                              },
                              error: (err) => {
                                console.error('Cannot get acl list', err)
                              }
                            })
                          }
                          else {
                            this.permissionsService.flushPermissions();
                            this.afa.signOut()
                            AppUtils.toast_error('Utilisateur introuvable', 'Veuillez contacter le service technique');
                          }
                        },
                        error: (error) => {
                          console.error(error)
                          this.permissionsService.flushPermissions();
                          this.afa.signOut()
                        }
                      });
    })
  }

  requestPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe({
        next: (token:any) => { 
          console.log('Permission granted! Ready to receive push notifications'); 

          this.afa.currentUser.then((user:any) => {
            if (user){
              this.terminalService.setTerminalToken(user.uid, token).then(() => {
                console.log('token updated')
              }, console.error);
              console.debug('Set terminal token')
            }
          })
        },
        error: (error) => { console.error(error); },  
      });
  }
}


