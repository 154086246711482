<div class="card">
  <div class="card-header">Images téléchargées</div>
  <div class="card-body">
    <label class="btn btn-default">
      <input class="form-control" placeholder="télécharge l'image" type="file" (change)="selectFile($event)" />
    </label>
    <ul class="list-group list-group-flush" *ngFor="let file of fileInfos">
      <li class="list-group-item">
        <div class="row">
          <div class="col-6">
            <img [src]="getFile(file)" />
          </div>
          <div class="col-6">
            <a target="_blank" [href]="getFile(file)">{{ file }}</a>
          </div>
        </div>
        
      </li>
    </ul>
    <button class="btn btn-primary" [(disabled)]="!selectedFiles" #download (click)="upload()">télecharger</button>
    <div *ngIf="message && message != ''" class="alert alert-light" role="alert">{{ message }}</div>
  </div>
</div>

