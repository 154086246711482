import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) { }

  upload(file:any,url:string,filename:any): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    (filename) ? formData.append('file', file,filename as any) : formData.append('file', file);
    const req = new HttpRequest('POST', `${environment.API_URL}/${url}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(url:string,file:any): Observable<any> {
    return this.http.get(`${environment.API_URL}/${url}/${file}`);
  }
}
