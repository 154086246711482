<div class="card">
    <div class="card-header border-0 pb-0">
        <div class="separator"></div>
        <div class="mr-auto">
            <h4 class="card-title mb-2">{{title}}</h4>
            <p class="fs-12 mb-0">{{subTitle}}</p>
        </div>
    </div>
    <div class="card-body  text-center">
        <div id="radialChart">
            <apx-chart #chart 
                        [series]="chartOptions.series" 
                        [chart]="chartOptions.chart" 
                        [title]="chartOptions.title"
                        [plotOptions]="chartOptions.plotOptions"
                        [labels]="chartOptions.labels"
                        [fill]="chartOptions.fill"
                        [stroke]="chartOptions.stroke">
            </apx-chart>
        </div>
        <h3 class="fs-28 text-black font-w500">{{expense | currency : 'XOF'}}</h3>
        <span class="mb-3 d-block">de {{total | currency : 'XOF'}}</span>
        <p class="fs-14">{{description}}</p>
        <!--<a class="btn btn-outline-primary">More Details</a>-->
    </div>
</div>