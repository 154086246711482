  <div class="deznav-scroll mm-active ps ps--active-y">
    <ul class="metismenu mm-show" id="menu">
      <li *ngxPermissionsOnly="['dashboard']">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/dashboard" aria-expanded="false">
        <i class="material-icons">trending_up</i>
        <span class="nav-text">Tableau de bord</span>
      </a>
      </li>
      <li *ngxPermissionsOnly="['companies']">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/companies" aria-expanded="false">
        <i class="material-icons">business</i>
        <span class="nav-text">Entreprises</span>
      </a>
      </li>
      <li *ngxPermissionsOnly="['professionnal']">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/company" aria-expanded="false">
        <i class="material-icons">business</i>
        <span class="nav-text">Mon entreprise</span>
      </a>
      </li>
      <li *ngxPermissionsOnly="['restaurants']">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/restaurants" aria-expanded="false">
          <i class="material-icons">storefront</i>          
        <span class="nav-text">Restaurants</span>
      </a>
      </li>
      <li *ngxPermissionsOnly="['districts']" routerLinkActive="active">
        <a class="has-arrow ai-icon" routerLink="/districts" aria-expanded="false">
        <i class="material-icons">map</i>
        <span class="nav-text">Quartiers</span>
      </a>
      </li>
      <li *ngxPermissionsOnly="['orders']">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/orders" aria-expanded="false">
          <i class="material-icons">receipt</i>
          <span class="nav-text">Commandes</span>
        </a>
      </li>
      <li *ngxPermissionsOnly="['payment-transaction']" routerLinkActive="active">
        <a class="has-arrow ai-icon" routerLinkActive="active" routerLink="/payment-transaction" aria-expanded="false">
          <i class="material-icons">paid</i>
          <span class="nav-text">Paiements</span>
        </a>
      </li>
      <li>
        <a class="has-arrow ai-icon" (click)="openModal(confirmDisconnect)" aria-expanded="false">
        <i class="material-icons">logout</i>
        <span class="nav-text">Déconnexion</span>
      </a>
      </li>
    </ul>
  </div>
  <ng-template #confirmDisconnect>
    <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
        Deconnexion!</h3>
      <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
      <p>êtes vous sur de vouloir vous déconnecter ? </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modalRef.hide()">Annuler</button>
      <button type="button" (click)="logout()" class="btn btn-primary shadow-none"
        (click)="modalRef.hide()">Valider</button>
    </div>
  </ng-template>
       