import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCategoryCrudService } from '../base-category-crud.service';
@Injectable({
  providedIn: 'root'
})
export class OptCategoryService extends BaseCategoryCrudService{

  constructor(_httpClient:HttpClient) { 
    super(_httpClient);
    this.controllerName = "categories/options";
  }
}
