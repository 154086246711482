import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseCRUDService {
    controllerName!:string;
    protected http: HttpClient;

    constructor(_http:HttpClient) { 
      this.http = _http;
    }

    copyImages(id:number){
      return this.http.get(`${environment.API_URL}/${this.controllerName}/${id}/photos/copy`)
    }

    getDataWithQb(query:string)
    {
      return this.http.get(`${environment.API_URL}/${this.controllerName}?${query}`)
    }

    getAll(){
      return this.http.get(`${environment.API_URL}/${this.controllerName}`);
    }

    getOne(id:any){
      return this.http.get(`${environment.API_URL}/${this.controllerName}/${id}`);
    }

    createOne(body:any){
      return this.http.post(`${environment.API_URL}/${this.controllerName}`, body);
    }

    createMany(body:any[]){
      return this.http.post(`${environment.API_URL}/${this.controllerName}/bulk`,{bulk : body});
    }

    updateOne(id:any, body:any){
      return this.http.patch(`${environment.API_URL}/${this.controllerName}/${id}`,body);
    }

    updateByUUID(uuid:string, body:any){
      return this.http.patch(`${environment.API_URL}/${this.controllerName}/${uuid}`,body);
    }

    replaceOne(id:any, body:any){
      return this.http.put(`${environment.API_URL}/${this.controllerName}/${id}`,body);
    }

    delete(id:any){
      return this.http.delete(`${environment.API_URL}/${this.controllerName}/${id}`);
    }

    /**
     * HTML string representing the details of the entity to display in the details modal
     * @param data the datatable row data
     * @returns 
     */
    getDetails(rowData: any): Promise<string>  {
      return Promise.resolve('');
    }
}