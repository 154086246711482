import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { QuickBarComponent } from './quick-bar/quick-bar.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { FODatatableComponent } from './datatable/datatable.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { ModalDeleteConfirmComponent } from './modal-delete-confirm/modal-delete-confirm.component';
import { PayexpessComponent } from './payexpess/payexpess.component';
import { PipesModule } from '../pipes/pipes.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StatChartComponent } from './stat-chart/stat-chart.component';
import {NgApexchartsModule} from "ng-apexcharts";
import { DailyStatExpenseComponent } from './daily-stat-expense/daily-stat-expense.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { TrendingListComponent } from './trending-list/trending-list.component';
import { RestaurantTrendingListItemComponent } from './trending-list/restaurant-trending-list-item/restaurant-trending-list-item.component';

@NgModule({
  declarations: [TopNavbarComponent,
                 SideNavbarComponent,
                 QuickBarComponent,
                 UploadFilesComponent,
                 FODatatableComponent,
                 ErrorHandlerComponent,
                 ModalDeleteConfirmComponent,
                 PayexpessComponent,
                 StatChartComponent,
                 DailyStatExpenseComponent,
                 CardTitleComponent,
                 TrendingListComponent,
                 RestaurantTrendingListItemComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    PipesModule,
    SweetAlert2Module.forRoot(),
    RouterModule,
    NgxPermissionsModule,
    NgApexchartsModule,
  ],
  exports : [TopNavbarComponent,
             SideNavbarComponent,
             QuickBarComponent,
             UploadFilesComponent,
             FODatatableComponent,
             ErrorHandlerComponent,
             PayexpessComponent,
             StatChartComponent,
             CardTitleComponent,
             ModalDeleteConfirmComponent,
             DailyStatExpenseComponent,TrendingListComponent,
             RestaurantTrendingListItemComponent]
})
export class SharedModule { 
  
}
