export class DateUtils {

  static getOffset() {
    let offsetString = "";
    let offset = new Date().getTimezoneOffset();
    let offsetInhour = Math.abs(offset / 60);
    let hour = Math.floor(offsetInhour);
    let minutes = Number((offsetInhour - Math.floor(offsetInhour)).toFixed(1)) * 60;

    if (offset < 0) offsetString = (hour < 10) ? `+0${hour}` : `+${hour}`;
    else offsetString = (hour < 10) ? `-0${hour}` : `-${hour}`;

    if (minutes == 0) offsetString += `00`;
    else offsetString += minutes;

    return offsetString;
  }

  static slotTimeToDate(dt:Date, slotTime:string) {
    let intervalOpening = this.getHMValueFromStr(slotTime);
    dt.setHours(intervalOpening.h);
    dt.setMinutes(intervalOpening.m);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  static getHMValueFromStr(str:string) {
    return { h: Number(str.split(":")[0]), m: Number(str.split(":")[1]) };
  }

  static getFormatedDate(timestamp: number) {
    return  new Date(timestamp).toLocaleDateString() + ' ' + new Date(timestamp).toLocaleTimeString();
  }

  static datePipe() {
    return { transform: (value:any) => this.getFormatedDate(value) };
  }
}