import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from '@angular/router';
import { UserService } from '..';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  user:any;
  constructor(private afAuth:AngularFireAuth, private userService:UserService, private _router:Router) { }

  public disconnect(){
    this.logout();
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this._router.navigate(['/auth']);
    });
  }
}
