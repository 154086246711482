import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle
} from "ng-apexcharts";

/*export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  colors:string[];
  dataLabels?:{enabled:boolean};
  markers:{shape:String};

};*/


@Component({
  selector: 'fo-stat-chart',
  templateUrl: './stat-chart.component.html',
  styleUrls: ['./stat-chart.component.scss']
})
export class StatChartComponent implements OnInit, OnChanges {

  @ViewChild("chart") chart!: ChartComponent;
  @Input() title: string = "";
  @Input() evolution: string = "+2.7";
  @Input() value: number = 0;

  public chartOptions: Partial<any> = {
    series: [
    ],
    chart: {
      type: 'area',
      height: 100,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }

    },

    colors: ['#FF720D'],
    dataLabels: {
      enabled: false,
    },
    markers: {
      shape: "circle",
    },

    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 3,
      curve: 'straight',
      colors: ['#FF720D'],
    },

    grid: {
      show: false,
      borderColor: '#eee',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0

      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          fontSize: '12px',
        }
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
      colors: '#FAC7B6'
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val:any) {
          return val
        }
      }
    }
  };
  @Input() seriesdata!: {name : string, data : number[]};
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.seriesdata){
      this.chartOptions = {...this.chartOptions, series : [this.seriesdata]};
    }
  }

}
