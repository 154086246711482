import { Component, Input, OnInit } from '@angular/core';
import { IRestaurantOrderStat } from '../../interfaces';

@Component({
  selector: 'app-trending-list',
  templateUrl: './trending-list.component.html',
  styleUrls: ['./trending-list.component.scss']
})
export class TrendingListComponent implements OnInit {
  @Input() restaurants:Array<IRestaurantOrderStat> = [];
  @Input() title!:string;
  @Input() subTitle!:string;
  constructor() { }

  ngOnInit(): void {
  }

}
