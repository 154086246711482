import { Component, HostListener, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UserService,AclService } from '../../services';
@Component({
  selector: 'fo-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  autorisePageList:string[] = [];
  isActive:boolean = true;

  constructor(private _authFire: AngularFireAuth,private _router:Router,private acl_service:AclService, private userService:UserService) { }

  async ngOnInit() {
    this.resizeScreen();
    this.acl_service.getAcl().subscribe({
      next : (aclList:any) => {
        this.autorisePageList = aclList;
      },
      error : (err) => {
        console.error('Cannot get acl list ', err)
      }
    })
  }

  activeMenu(){
    this.isActive = (this.isActive) ? false : true;
    (this.isActive) ? document.getElementById("main-wrapper")?.classList.add("menu-toggle") :  document.getElementById("main-wrapper")?.classList.remove("menu-toggle");
  }

  @HostListener("window:resize",[])
  private resizeScreen(){
    (window.innerWidth <= 991) ? document.getElementById("body")?.setAttribute("data-sidebar-style","overlay") : document.getElementById("body")?.setAttribute("data-sidebar-style","full");
  }

  signOutUser(){
    return this._authFire.signOut();
  }

  logout()
  {
    this.signOutUser().then(() => {
      this._router.navigate(['']);
    });
  }


}
