import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCategoryCrudService } from '../base-category-crud.service';
@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseCategoryCrudService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "categories/dishes";
  }

  fromMenuID(menuID:number){
    return this.http.get(`${environment.API_URL}/${this.controllerName}/menu/${menuID}`);
  }
}
