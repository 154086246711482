import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AclService {

  constructor(private http:HttpClient, private u_service:UserService) { 

  }

  getAcl(){
    return this.http.get(`${environment.API_URL}/roles-guard-acl/get/acl`)
  }
}
