import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "companies"
  }

  credit(data:any){
    return this.http.post(`${environment.API_URL}/orders/company/credit`,data)
  }

  creditGroup(data:any){
    return this.http.post(`${environment.API_URL}/orders/company/accounts/credit`,data)
  }

  solde(id:number){
    return this.http.get(`${environment.API_URL}/companies/${id}/solde`)
  }

}
