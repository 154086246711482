import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestQueryBuilder,CondOperator } from "@nestjsx/crud-request";
import { BaseCRUDService } from '..';
@Injectable({
  providedIn: 'root'
})
export class RestaurantService extends BaseCRUDService{

  constructor(_httpCLient:HttpClient) { 
    super(_httpCLient);
    this.controllerName = "restaurants";
  }

  menuFromRestID(restID:number){
    return  this.getDataWithQb(RequestQueryBuilder.create().setJoin({field:"menu"}).setFilter({ field : "id", operator : CondOperator.EQUALS, value : restID }).query());
  }
}
