import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UploadFileService } from './upload-file.service';
import Compressor from 'compressorjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {

  @Input() upload_url!:string;
  @Input() get_file_url!:string;
  @Output() uploadEvent:EventEmitter<any> = new EventEmitter();
  @ViewChild("download") downloadButton!:ElementRef;
  selectedFiles!: FileList;
  currentFile!: File |Blob |any;
  inProgress:boolean = false;
  progress = 0;
  message = '';

  fileInfos:Array<string> = [];

  constructor(private uploadService: UploadFileService) { }

  ngOnInit(): void {
  }

  selectFile(event:any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    this.progress = 0;
    this.inProgress = true
    this.currentFile = this.selectedFiles.item(0) as any;
    let _self = this;
    new Compressor(this.currentFile, {
      quality: 0.6,
      width : 800,
      success(result:any) {
        let nameArr = _self.currentFile.name.split(".");
        let fileName = Math.random().toString(36).substr(2,9)+"."+nameArr[nameArr.length-1];
        _self.uploadRequest(result,fileName);
        //_self.selectedFiles = undefined;
      },
      error(err) {
        console.log(err.message);
      },
    });
  }

  uploadRequest(result:any,fileName:any){
    this.uploadService.upload(result,this.upload_url,fileName).subscribe(
      (event:any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          //_self.message = event.body.message;
          this.inProgress = false;
          this.fileInfos.push(fileName);
          this.uploadEvent.emit(fileName);
        }
      },
      err => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.currentFile = undefined;
      });
  }
  getFile(name:string){
    return `${environment.API_URL}/${this.get_file_url}/${name}`;
  }
}
