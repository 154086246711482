<div class="col-12">
    <div class="row">
        <input *ngIf="filterConfig.text" type='text' class='col-3 form-control' (keyup)="filterDataTable($event)" placeholder="Recherche" />
        <div *ngIf="filterConfig.date" class="col-xs-12 col-12 col-md-4 form-group mb-3">
          <input type="date"
                  [(ngModel)]="dateRange.start"
                  placeholder="Date debut"
                  class="form-control">
        </div>
        <div *ngIf="filterConfig.date" class="col-xs-12 col-12 col-md-4 form-group mb-3">
          <input type="date"
                  [(ngModel)]="dateRange.end"
                  placeholder="Date fin"
                  class="form-control">
        </div>
        <div *ngIf="filterConfig.date" class="col-xs-12 col-12 col-md-1">
            <button (click)="filterByDate()" class="btn btn-primary align-items-right" >Filtrer</button>
        </div>
        
      </div>  
</div>

<ngx-datatable *ngIf="paging" class="material dataTables_wrapper table table-responsive-md" [rows]="rows" [columns]="columns" columnMode="force" headerHeight="60"
    footerHeight="50" rowHeight="auto" [externalPaging]="true"
    [messages]="{emptyMessage: 'Aucun résultat.', totalMessage: 'total', selectedMessage: 'sélectionner'}"
    [count]="page.totalElements" [offset]="page.pageNumber-1" [limit]="page.size" (page)="setPage($event)"
    [loadingIndicator]="isLoading">

    <ng-template #actionTpl let-row="row" let-value="value">
        <a style="display: block;margin: 15px;float: left;" [routerLink]="editUrl+'/'+value">
            <i class='material-icons'>mode_edit</i>
        </a>
        <a style="display: block;margin: 15px;float: left;" href='#'>
            <i class='material-icons'>delete_outline</i>
        </a>
    </ng-template>
</ngx-datatable>
<ngx-datatable *ngIf="!paging" class="material dataTables_wrapper table table-responsive-md" [rows]="rows" [columns]="columns" columnMode="force" headerHeight="60"
    footerHeight="50" rowHeight="auto"
    [messages]="{emptyMessage: 'Aucun résultat.', totalMessage: 'total', selectedMessage: 'sélectionner'}" [limit]="10"
    [loadingIndicator]="isLoading">

    <ng-template #deleteTpl let-row="row" let-value="value">
        <button class="btn text-danger"
            [swal]="{ title: 'voulez vous vraiment supprimer cet élement ?', showCancelButton: true }"
            (confirm)="delete(value)">
            <i class='material-icons'>delete_outline</i>
        </button>
    </ng-template>

    <ng-template #actionTpl let-row="row" let-value="value">
        <button *ngIf="displayEdit()" class="btn text-default" (click)="openModal(updateModal, row)">
            <i class='material-icons'>mode_edit</i>
        </button>
        <button *ngIf="editUrl && editUrl != ''" class="btn text-default" (click)="goto(value)">
            <i class='material-icons'>visibility</i>
        </button>
        <button *ngIf="rowHasDetails" class="btn text-default" (click)="openModal(detailsModal, row)">
          <i class='material-icons'>visibility</i>
        </button>
        <button *ngIf="deletableRow" class="btn text-danger"
            [swal]="{ title: 'Voulez vous vraiment supprimer cet élement ?', showCancelButton: true }"
            (confirm)="delete(value)">
            <i class='material-icons'>delete_outline</i>
        </button>
    </ng-template>

    <ng-template #multipleTpl let-row="row" let-value="value">
        <label class="switch">
            <input (ngModelChange)="row.isMulti = $event;update(row)" [ngModel]="row.isMulti" type="checkbox">
            <span class="slider round"></span>
        </label>
    </ng-template>
    <ng-template #groupDisplay let-row="row" let-value="value">
        <label>{{getNameFromId(value)}}</label>
    </ng-template>
    <ng-template #stockTpl let-row="row" let-value="value">
        <label class="switch">
            <input (ngModelChange)="row.inStock = $event;update(row)" [ngModel]="row.inStock" type="checkbox">
            <span class="slider round"></span>
        </label>
    </ng-template>
</ngx-datatable>

<ng-template #updateModal>
    <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
        Mise à jour</h3>
      <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
    <div *ngFor="let col of columns" class="form-row">
        <div *ngIf="col.editable && col.editable.type != 'select'" class="col-12">
            <div class="form-group">
                <label>{{col.name}}</label>
                <input class="form-control" [type]="col.editable?.type" (ngModelChange)="modelFromString(col.prop,$event)" [ngModel]="getModelfromString(col.prop)" />
            </div>
        </div> 
        <div *ngIf="col.editable && col.editable.type == 'select'" class="col-12">
            <div class="form-group">
                <label>{{col.name}}</label>
                <select class="form-control" (ngModelChange)="modelFromString(col.prop,$event)" [ngModel]="getModelfromString(col.prop)">
                    <option *ngFor="let opt of col.editable.choices" [value]="opt.value" >{{opt.label}}</option>
                </select>
            </div>
        </div>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modalRef.hide()">Annuler</button>
      <button type="button" (click)="update(currentRow)" class="btn btn-primary shadow-none">Valider</button>
    </div>
  </ng-template>

<ng-template #detailsModal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
      Détails</h3>
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close"><span
        aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body" [innerHTML]="details | async"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRef.hide()">Annuler</button>
  </div>
</ng-template>