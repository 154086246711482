import { EventEmitter, Injectable } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { NotifierNotificationOptions } from "angular-notifier/lib/models/notifier-notification.model";
import { OrderStatus } from "../order/enums/order.status";
import { OrderInfoDTO } from "./dto/order-info.dto";

const cancelOrderNotification = (order: OrderInfoDTO) : NotifierNotificationOptions => {
  return { type: 'error', message: `la commande #${order.orderNum} a été annulée` };
}

const newOrderNotification = (order: OrderInfoDTO) : NotifierNotificationOptions => {
  const { name, tel } = order.restaurant;
  return { type: 'info', message: `Une nouvelle commande #${order.orderNum} est disponible pour le restaurant ${name} (${tel})` };
}

const completeOrderNotification = (order: OrderInfoDTO) : NotifierNotificationOptions => {
  const { name, tel } = order.restaurant;
  return { type: 'success', message: `La commande #${order.orderNum} a été finalisée par le restaurant ${name} (${tel})` };
}

const processedOrderNotification = (order: OrderInfoDTO) : NotifierNotificationOptions => {
  const { name, tel } = order.restaurant;
  return { type: 'default', message: `La commande #${order.orderNum} est en cours de traitement par le restaurant ${name} (${tel})` };
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _orderEvt: EventEmitter<void> = new EventEmitter<void>();
  private notifSound: HTMLAudioElement;

  constructor(private notifierService: NotifierService) {
    this.notifSound = new Audio('../../../assets/sounds/pop-alert.mp3');
    this.notifSound.load();
  }

  showOrderStatusUpdateNotification(order: OrderInfoDTO) {
    this.notifierService.show(this.getOrderNotification(order))
    this.playNotifSound()
    this._orderEvt.emit()
  }

  private playNotifSound() {
    this.notifSound.play();
  }

  getOrderNotification(order: OrderInfoDTO): NotifierNotificationOptions {
    switch(order.status) {
      case OrderStatus.CANCELED: return cancelOrderNotification(order)
      case OrderStatus.PENDING: return newOrderNotification(order)
      case OrderStatus.COMPLETE: return completeOrderNotification(order)
      case OrderStatus.PROCESSED: return processedOrderNotification(order)
    }
    return {type:"unknown",message:"unknown"}
  }

  get orderEvt(): EventEmitter<void> {
    return this._orderEvt;
  }
}