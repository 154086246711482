import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseCRUDService } from 'src/app/shared/services/base.crud.service';

@Component({
  selector: 'fo-modal-delete-confirm',
  templateUrl: './modal-delete-confirm.component.html',
  styleUrls: ['./modal-delete-confirm.component.css']
})
export class ModalDeleteConfirmComponent implements OnInit {
  @Input() title = "Suppression !";
  @Input() message = "êtes vous sur de supprimer cet élément ?";
  @Input() service!:BaseCRUDService;
  @Input() name!:string;
  @Input() deleteId!:any;
  @Input() modalRef!:BsModalRef;

  @Output() onSuccess:EventEmitter<any> = new EventEmitter();
  @Output() onError:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  delete(){
    this.service.delete(this.deleteId).subscribe({
      next : (data) => {
        this.onSuccess.emit(data)
      },
      error : (err) => {
        this.onError.emit(err)
      }
    })
  }

}
