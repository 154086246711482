import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AclService } from '../../services';

@Component({
  selector: 'fo-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css']
})
export class SideNavbarComponent implements OnInit {
  autorisePageList:string[] = [];
  modalRef: any;
  constructor(private _authFire: AngularFireAuth,private _router:Router, private bsmService:BsModalService) { }

  async ngOnInit() {
  
  }

  signOutUser(){
    return this._authFire.signOut();

  }

  openModal(template:TemplateRef<any>){
    this.modalRef = this.bsmService.show(template);
  }

  logout()
  {
    this.signOutUser().then(() => {
      this._router.navigate(['']);
    });
  }
}
