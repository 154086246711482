import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestQueryBuilder,CondOperator } from "@nestjsx/crud-request";
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseCRUDService{

  constructor(_httpClient:HttpClient) { 
    super(_httpClient);
    this.controllerName = "menu"
  }

  menuInfoRequest(menuID:number){
    return this.getDataWithQb(RequestQueryBuilder.create()
            .setJoin({field:"dishes.category"})
            .setJoin({field:"dishes.supplements"})
            .setJoin({field:"dishes.supplements.category"})
            .setJoin({field:"dishes.options"})
            .setJoin({field:"dishes.options.category"})
            .setFilter({ field : "id", operator : CondOperator.EQUALS, value : menuID }).query());
  }
}
