import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDService } from '..';
@Injectable({
  providedIn: 'root'
})
export class TableService extends BaseCRUDService{

  constructor(_httpCLient:HttpClient) { 
    super(_httpCLient);
    this.controllerName = "tables";
  }
}
