import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StatsService } from '../../services';

@Component({
  selector: 'daily-stat-expense',
  templateUrl: './daily-stat-expense.component.html',
  styleUrls: ['./daily-stat-expense.component.scss']
})
export class DailyStatExpenseComponent implements OnInit, OnChanges {

  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() description!: string;
  @Input() expense!: string;
  @Input() total!: number;

  public chartOptions: any = {
    series: [0],
    chart: {
      height: 230,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 20,
          size: '65%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 10,
            opacity: 0.1
          }
        },
        track: {
          background: '#F8F8F8',
          strokeWidth: '100%',
          margin: 0, // margin is in pixels
        },
        dataLabels: {
          show: true,
          value: {
            offsetY: -7,
            color: '#111',
            fontSize: '20px',
            show: true,
          }
        }
      }
    },
    fill: {
      colors: ['#ff720d'],
    },
    stroke: {
    },
    labels: [''],
  }
  constructor(private _statService:StatsService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    let percent = ((parseInt(this.expense)*100)/this.total);
   if(this.total > 0) this.chartOptions = {...this.chartOptions, series : [isNaN(percent) ? 0 : percent.toFixed(2)]}
  }

}
