import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseCRUDService{

  constructor(_http:HttpClient,private afAuth: AngularFireAuth) { 
    super(_http);
    this.controllerName = "users";
  }

  checkPhone(tel:string){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/find/phone`,{tel});
  }
    
  firebaseUser(){
    return this.afAuth.currentUser;
  }

  subscription(body:any){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/subscription`,body)
  }
}
