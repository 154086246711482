import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayexpresseService {

  constructor(private http:HttpClient) { 

  }

  getPaymentToken(data:any){
    return this.http.post(`${environment.API_URL}/orders/company/payment/`,data,{
      headers : {"Accept" : "text/html"}
    });
  }
}
