import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class DistrictService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "district";
  }
}
