// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL:'https://dev.fast-order.fr',
  APP_URL:'https://fastorder-dashboard.firebaseapp.com',
  socketIoConfig: { url: 'wss://dev.fast-order.fr', options: {transports : ['websocket'], secure: true}},
  firebase : {
    apiKey: "AIzaSyBOg1Idml1dwqpDzu_VDCHFkV6wQrzEmHM",
    authDomain: "fastorder-31d34.firebaseapp.com",
    databaseURL: "https://fastorder-31d34.firebaseio.com",
    projectId: "fastorder-31d34",
    storageBucket: "fastorder-31d34.appspot.com",
    messagingSenderId: "292189246827",
    appId: "1:292189246827:web:6f73b11997a625e524682e",
    measurementId: "G-BD4MWD410W",
    vapidKey: 'BKtHTYGa3JbPju-qeIr82TvwcwEciFr9Uv0uIGEclVz6pBR-8YF_qSz6Jq1rUP1l-kHJm8VTXlXXESs1HKtQx2g'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
