<aside id="ms-quick-bar" class="ms-quick-bar fixed ms-d-block-lg">
	<ul class="nav nav-tabs ms-quick-bar-list" role="tablist">
		<li class="ms-quick-bar-item ms-has-qa" role="presentation" data-toggle="tooltip" data-placement="left" title="Launch To-do-list" data-title="To-do-list">
        <a href="#qa-toDo" aria-controls="qa-toDo" role="tab" data-toggle="tab">
          <i class="flaticon-list"></i>
        </a>
      </li>
		<li class="ms-quick-bar-item ms-has-qa" role="presentation" data-toggle="tooltip" data-placement="left" title="Launch Reminders" data-title="Reminders">
			<a href="#qa-reminder" aria-controls="qa-reminder" role="tab" data-toggle="tab"> <i class="flaticon-bell"></i>
			</a>
		</li>
		<li class="ms-quick-bar-item ms-has-qa" role="presentation" data-toggle="tooltip" data-placement="left" title="Launch Notes" data-title="Notes">
			<a href="#qa-notes" aria-controls="qa-notes" role="tab" data-toggle="tab"> <i class="flaticon-pencil"></i>
			</a>
		</li>
		<li class="ms-quick-bar-item ms-has-qa" role="presentation" data-toggle="tooltip" data-placement="left" title="Invite Members" data-title="Invite Members">
			<a href="#qa-invite" aria-controls="qa-invite" role="tab" data-toggle="tab"> <i class="flaticon-share-1"></i>
			</a>
		</li>
		<li class="ms-quick-bar-item ms-has-qa" role="presentation" data-toggle="tooltip" data-placement="left" title="Settings" data-title="Settings">
			<a href="#qa-settings" aria-controls="qa-settings" role="tab" data-toggle="tab"> <i class="flaticon-gear"></i>
			</a>
		</li>
	</ul>
	<div class="ms-configure-qa" data-toggle="tooltip" data-placement="left" title="Configure Quick Access">
		<a href="#"> <i class="flaticon-hammer"></i>
		</a>
	</div>
	<!-- Quick bar Content -->
	<div class="ms-quick-bar-content">
		<div class="ms-quick-bar-header clearfix">
			<h5 class="ms-quick-bar-title float-left">Title</h5>
			<button type="button" class="close ms-toggler" data-target="#ms-quick-bar" data-toggle="hideQuickBar" aria-label="Close"><span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="ms-quick-bar-body tab-content">
      <div role="tabpanel" class="tab-pane" id="qa-chat">
				<div class="ms-quickbar-container ms-chat-conversations ms-scrollable">
					<div class="card-header">
            <div class="ms-chat-header justify-content-between">
              <div class="ms-chat-user-container media clearfix">
                <div class="ms-chat-status ms-status-online ms-chat-img mr-3 align-self-center">
                  <img src="assets/img/costic/customer-3.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-chat-user-info mt-1">
                  <h6>John Doe</h6>
                  <span class="text-disabled fs-12">
                    Active Now
                  </span>
                </div>
              </div>
              <ul class="ms-list ms-list-flex ms-chat-controls">
                <li data-toggle="tooltip" data-placement="top" title="Call"> <i class="material-icons">local_phone</i>
                </li>
                <li data-toggle="tooltip" data-placement="top" title="Video Call"> <i class="material-icons">videocam</i>
                </li>
                <li data-toggle="tooltip" data-placement="top" title="Add to Chat"> <i class="material-icons">person_add</i>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body ms-scrollable">
            <div class="ms-chat-bubble ms-chat-message ms-chat-outgoing media clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-3.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                </div>
                <p class="ms-chat-time">10:33 pm</p>
              </div>
            </div>
            <div class="ms-chat-bubble ms-chat-message media ms-chat-incoming clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-5.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                </div>
                <p class="ms-chat-time">10:33 pm</p>
              </div>
            </div>
            <div class="ms-chat-bubble ms-chat-message ms-chat-outgoing media clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-3.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eget orci ex.</p>
                </div>
                <p class="ms-chat-time">10:36 pm</p>
              </div>
            </div>
            <div class="ms-chat-bubble ms-chat-message media ms-chat-incoming clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-5.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <p class="ms-chat-time">10:41 pm</p>
              </div>
            </div>
            <div class="ms-chat-bubble ms-chat-message ms-chat-outgoing media clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-3.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <p class="ms-chat-time">10:44 pm</p>
              </div>
            </div>
            <div class="ms-chat-bubble ms-chat-message ms-chat-incoming media clearfix">
              <div class="ms-chat-status ms-status-online ms-chat-img">
                <img src="assets/img/costic/customer-5.jpg" class="ms-img-round" alt="people">
              </div>
              <div class="media-body">
                <div class="ms-chat-text">
                  <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                </div>
                <p class="ms-chat-time">11:01 pm</p>
              </div>
            </div>
          </div>
          <div class="card-footer pt-0">
            <div class="ms-chat-textbox">
              <ul class="ms-list-flex mb-0">
                <li class="ms-chat-vn"><i class="material-icons">mic</i>
                </li>
                <li class="ms-chat-input">
                  <input type="text" name="msg" placeholder="Enter Message" value="">
                </li>
                <li>
                  <ul class="ms-chat-text-controls ms-list-flex">
                    <li> <i class="material-icons">tag_faces</i>
                    </li>
                    <li> <i class="material-icons">attach_file</i>
                    </li>
                    <li> <i class="material-icons">camera_alt</i>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
				</div>
      </div>
      <div role="tabpanel" class="tab-pane" id="qa-email">
				<div class="ms-quickbar-container ms-email-main ms-scrollable">
					<div class="card-header">
            <h6>Inbox</h6>
            <p>You have 17 Unread Messages</p>
            <ul class="ms-email-pagination">
              <li>50-100 of 985</li>
              <li class="ms-email-pagination-item">
                <a routerLink="/email" class="ms-email-pagination-link"> <i class="material-icons">keyboard_arrow_left</i> 
                </a>
              </li>
              <li class="ms-email-pagination-item ">
                <a routerLink="/email" class="ms-email-pagination-link"> <i class="material-icons">keyboard_arrow_right</i> 
                </a>
              </li>
            </ul>
          </div>
          <div class="ms-email-header">
            <ul class="ms-email-options">
              <li>
                <label class="ms-checkbox-wrap">
                  <input type="checkbox" class="ms-email-check-all" value=""> <i class="ms-checkbox-check"></i>
                </label>
                <div class="dropdown"> <a routerLink="/email" class="has-chevron" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Select
                  </a>
                  <ul class="dropdown-menu">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul class="ms-email-options">
              <li>
                <a routerLink="/email" class="text-disabled"> <i class="material-icons">refresh</i> Refresh</a>
              </li>
              <li>
                <a routerLink="/email" class="text-disabled"> <i class="material-icons">local_offer</i> Tags</a>
              </li>
              <li>
                <a routerLink="/email" class="text-disabled"> <i class="material-icons">folder</i> Folders</a>
              </li>
            </ul>
          </div>
          <!-- Email Content -->
          <div class="ms-email-content">
            <ul class="ms-scrollable">
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-1.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a routerLink="/email" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" routerLink="/email">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email pinned clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-2.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time"> <a routerLink="/email"><i class="material-icons">attachment</i></a> 2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a routerLink="/email" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" routerLink="/email">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" routerLink="/email">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-3.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-4.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-5.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-6.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email pinned clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-7.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-8.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="media ms-email pinned clearfix">
                <div class="ms-email-controls">
                  <label class="ms-checkbox-wrap">
                    <input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
                  </label> <i class="material-icons ms-pin-email">flag</i>
                </div>
                <div class="ms-email-img mr-3 ">
                  <img src="assets/img/costic/customer-9.jpg" class="ms-img-round" alt="people">
                </div>
                <div class="media-body ms-email-details"> <span class="ms-email-sender">John Doe</span>
                  <h6 class="ms-email-subject">[WordPress] New Comment</h6>  <span class="ms-email-time">2 Hours ago</span>
                  <p class="ms-email-msg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                </div>
                <div class="dropdown">
                  <a href="#" class="ms-hoverable-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="material-icons">more_vert</i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li class="ms-dropdown-list">
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Mark as read</span>
                        </div>
                      </a>
                      <a class="media p-2 ms-pin-email" href="#">
                        <div class="media-body"> <span>Flag</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Archive</span>
                        </div>
                      </a>
                      <a class="media p-2" href="#">
                        <div class="media-body"> <span>Delete</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
				</div>
			</div>
			<div role="tabpanel" class="tab-pane" id="qa-toDo">
				<div class="ms-quickbar-container ms-todo-list-container ms-scrollable">
					<form class="ms-add-task-block">
						<div class="form-group mx-3 mt-0  fs-14 clearfix">
							<input type="text" class="form-control fs-14 float-left" id="task-block" name="todo-block" placeholder="Add Task Block" value="">
							<button type="submit" class="ms-btn-icon bg-primary float-right"><i class="material-icons text-disabled">add</i>
							</button>
						</div>
					</form>
					<ul class="ms-todo-list">
						<li class="ms-card ms-qa-card ms-deletable">
							<div class="ms-card-header clearfix">
								<h6 class="ms-card-title">Task Block Title</h6>
								<button data-toggle="tooltip" data-placement="left" title="Add a Task to this block" class="ms-add-task-to-block ms-btn-icon float-right"> <i class="material-icons text-disabled">add</i> 
								</button>
							</div>
							<div class="ms-card-body">
								<ul class="ms-list ms-task-block">
									<li class="ms-list-item ms-to-do-task ms-deletable">
										<label class="ms-checkbox-wrap ms-todo-complete">
											<input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
										</label> <span> Task to do </span>
										<button type="submit" class="close"><i class="flaticon-trash ms-delete-trigger"> </i>
										</button>
									</li>
									<li class="ms-list-item ms-to-do-task ms-deletable">
										<label class="ms-checkbox-wrap ms-todo-complete">
											<input type="checkbox" value=""> <i class="ms-checkbox-check"></i>
										</label> <span>Task to do</span>
										<button type="submit" class="close"><i class="flaticon-trash ms-delete-trigger"> </i>
										</button>
									</li>
								</ul>
							</div>
							<div class="ms-card-footer clearfix">
								<a href="#" class="text-disabled mr-2"> <i class="flaticon-archive"> </i> Archive</a>
								<a href="#" class="text-disabled  ms-delete-trigger float-right"> <i class="flaticon-trash"> </i> Delete</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div role="tabpanel" class="tab-pane" id="qa-reminder">
				<div class="ms-quickbar-container ms-reminders">
					<ul class="ms-qa-options">
						<li>
							<a href="#" data-toggle="modal" data-target="#reminder-modal"> <i class="flaticon-bell"></i> New Reminder</a>
						</li>
					</ul>
					<div class="ms-quickbar-container ms-scrollable">
						<div class="ms-card ms-qa-card ms-deletable">
							<div class="ms-card-body">
								<p>Developer Meeting in Block B</p> <span class="text-disabled fs-12"><i class="material-icons fs-14">access_time</i> Today - 3:45 pm</span>
							</div>
							<div class="ms-card-footer clearfix">
								<div class="ms-note-editor float-right">
									<a href="#" class="text-disabled mr-2" data-toggle="modal" data-target="#reminder-modal"> <i class="flaticon-pencil"> </i> Edit</a>
									<a href="#" class="text-disabled  ms-delete-trigger"> <i class="flaticon-trash"> </i> Delete</a>
								</div>
							</div>
						</div>
						<div class="ms-card ms-qa-card ms-deletable">
							<div class="ms-card-body">
								<p>Start adding change log to version 2</p> <span class="text-disabled fs-12"><i class="material-icons fs-14">access_time</i> Tomorrow - 12:00 pm</span>
							</div>
							<div class="ms-card-footer clearfix">
								<div class="ms-note-editor float-right">
									<a href="#" class="text-disabled mr-2" data-toggle="modal" data-target="#reminder-modal"> <i class="flaticon-pencil"> </i> Edit</a>
									<a href="#" class="text-disabled  ms-delete-trigger"> <i class="flaticon-trash"> </i> Delete</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div role="tabpanel" class="tab-pane" id="qa-notes">
				<ul class="ms-qa-options">
					<li>
						<a href="#" data-toggle="modal" data-target="#notes-modal"> <i class="flaticon-sticky-note"></i> New Note</a>
					</li>
					<li>
						<a href="#"> <i class="flaticon-excel"></i> Export to Excel</a>
					</li>
				</ul>
				<div class="ms-quickbar-container ms-scrollable">
					<div class="ms-card ms-qa-card ms-deletable">
						<div class="ms-card-header">
							<h6 class="ms-card-title">Don't forget to check with the designer</h6>
						</div>
						<div class="ms-card-body">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vulputate urna in faucibus venenatis. Etiam at dapibus neque, vel varius metus. Pellentesque eget orci malesuada, venenatis magna et</p>
							<ul class="ms-note-members clearfix mb-0">
								<li class="ms-deletable">
									<img src="assets/img/people/people-3.jpg" alt="member">
								</li>
								<li class="ms-deletable">
									<img src="assets/img/people/people-5.jpg" alt="member">
								</li>
							</ul>
						</div>
						<div class="ms-card-footer clearfix">
							<div class="dropdown float-left">
								<a href="#" class="text-disabled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="flaticon-share-1"></i> Share</a>
								<ul class="dropdown-menu">
									<li class="dropdown-menu-header">
										<h6 class="dropdown-header ms-inline m-0"><span class="text-disabled">Share With</span></h6>
									</li>
									<li class="dropdown-divider"></li>
									<li class="ms-scrollable ms-dropdown-list ms-members-list">
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-10.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>John Doe</span>
											</div>
										</a>
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-9.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>Raymart Sandiago</span>
											</div>
										</a>
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-7.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>Heather Brown</span>
											</div>
										</a>
									</li>
								</ul>
							</div>
							<div class="ms-note-editor float-right">
								<a href="#" class="text-disabled mr-2" data-toggle="modal" data-target="#notes-modal"> <i class="flaticon-pencil"> </i> Edit</a>
								<a href="#" class="text-disabled  ms-delete-trigger"> <i class="flaticon-trash"> </i> Delete</a>
							</div>
						</div>
					</div>
					<div class="ms-card ms-qa-card ms-deletable">
						<div class="ms-card-header">
							<h6 class="ms-card-title">Perform the required unit tests</h6>
						</div>
						<div class="ms-card-body">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vulputate urna in faucibus venenatis. Etiam at dapibus neque, vel varius metus. Pellentesque eget orci malesuada, venenatis magna et</p>
							<ul class="ms-note-members clearfix mb-0">
								<li class="ms-deletable">
									<img src="assets/img/people/people-9.jpg" alt="member">
								</li>
							</ul>
						</div>
						<div class="ms-card-footer clearfix">
							<div class="dropdown float-left">
								<a href="#" class="text-disabled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="flaticon-share-1"></i> Share</a>
								<ul class="dropdown-menu">
									<li class="dropdown-menu-header">
										<h6 class="dropdown-header ms-inline m-0"><span class="text-disabled">Share With</span></h6>
									</li>
									<li class="dropdown-divider"></li>
									<li class="ms-scrollable ms-dropdown-list ms-members-list">
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-10.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>John Doe</span>
											</div>
										</a>
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-9.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>Raymart Sandiago</span>
											</div>
										</a>
										<a class="media p-2" href="#">
											<div class="mr-2 align-self-center">
												<img src="assets/img/people/people-7.jpg" class="ms-img-round" alt="people">
											</div>
											<div class="media-body"> <span>Heather Brown</span>
											</div>
										</a>
									</li>
								</ul>
							</div>
							<div class="ms-note-editor float-right">
								<a href="#" class="text-disabled mr-2" data-toggle="modal" data-target="#notes-modal"> <i class="flaticon-pencil"> </i> Edit</a>
								<a href="#" class="text-disabled  ms-delete-trigger"> <i class="flaticon-trash"> </i> Delete</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div role="tabpanel" class="tab-pane" id="qa-invite">
				<div class="ms-quickbar-container text-center ms-invite-member"> <i class="flaticon-network"></i>
					<p>Invite Team Members</p>
					<form>
						<div class="ms-form-group">
							<input type="text" placeholder="Member Email" class="form-control" name="invite-email" value="">
						</div>
						<div class="ms-form-group">
							<button type="submit" name="invite-member" class="btn btn-primary w-100">Invite</button>
						</div>
					</form>
				</div>
			</div>
			<div role="tabpanel" class="tab-pane" id="qa-settings">
				<div class="ms-quickbar-container text-center ms-invite-member">
					<div class="row">
						<div class="col-md-12 text-left mb-5">
							<h4 class="section-title bold">Customize</h4>
							<div>
								<label class="switch">
									<input type="checkbox" id="dark-mode"> <span class="slider round"></span>
								</label> <span> Dark Mode </span>
							</div>
							<div>
								<label class="switch">
									<input type="checkbox" id="remove-quickbar"> <span class="slider round"></span>
								</label> <span> Remove Quickbar </span>
							</div>
						</div>
						<div class="col-md-12 text-left">
							<h4 class="section-title bold">Keyboard Shortcuts</h4>
							<p class="ms-directions mb-0"><code>Esc</code> Close Quick Bar</p>
							<p class="ms-directions mb-0"><code>Alt + (1 -&gt; 6)</code> Open Quick Bar Tab</p>
							<p class="ms-directions mb-0"><code>Alt + Q</code> Enable Quick Bar Configure Mode</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</aside>