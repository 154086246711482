import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class TerminalService extends BaseCRUDService{
  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "terminal";
  }

  getTerminalByUuid(uuid: string)
  {
    return this.http.get(`${environment.API_URL}/terminal/uuid/${uuid}`).toPromise();
  }

  getStatus(_uuid:string){
    return this.http.get(`${environment.API_URL}/terminal/uuid/${_uuid}/connected`).toPromise();
  }

  setOnOffLine(_uuid:string, _connected:boolean){
    return this.http.patch(`${environment.API_URL}/terminal/uuid/${_uuid}`, {connected: _connected}).toPromise();
  }

  setTerminalToken(uuid:string, fcmToken: string)
  {
    return this.http.patch(`${environment.API_URL}/terminal/uuid/${uuid}`, {fcmToken: fcmToken}).toPromise();
  }
}
