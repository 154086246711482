import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import "@angular/compiler";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { FileUploadModule } from '@iplab/ngx-file-upload';
import { CommonModule } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TagInputModule } from 'ngx-chips';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
//import {NgxEchartsModule} from "ngx-echarts";

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';


import { NgxSelectModule } from 'ngx-select-ex';

import {CollapseModule} from "ngx-bootstrap/collapse";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NotifierModule } from 'angular-notifier';
import { SocketIoModule } from 'ngx-socket-io';
import { ServiceWorkerModule } from '@angular/service-worker';
import {NgApexchartsModule} from "ng-apexcharts";
import { PipesModule } from './shared/pipes/pipes.module';
import { SharedModule } from './shared/components/shared.module';
import { TokenInterceptor } from './shared/interceptors/token.interceptors';
import { PreloaderComponent } from './shared/components/preloader/preloader.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireAuthGuardModule,
    SweetAlert2Module.forRoot(),
    /*NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),*/
    NgxSelectModule,
    TagInputModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    //FileUploadModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    PipesModule,
    SharedModule,
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NotifierModule.withConfig({ 
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10,
        },
      }
    }),
    SocketIoModule.forRoot(environment.socketIoConfig),
    NgApexchartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [{ 
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true 
  },{provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
