import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from '../services/user/user.service';
import { from, Observable, Subscriber } from 'rxjs';
import firebase from 'firebase/compat/app'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public afAuth: AngularFireAuth, private _userService:UserService) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next)) as any;
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const user: firebase.User = await this._userService.firebaseUser() as any;
    if (user) {
      const token:string = await user.getIdToken();
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
      
      return next.handle(authReq).toPromise();
    }
    return 
  }
}