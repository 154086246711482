import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '..';

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "transaction";
  }

  companyLast(ob:any)
    {
        return this.http.post(`${environment.API_URL}/${this.controllerName}/company/get`,ob);
    }
    profileLast(ob:any)
    {
        return this.http.post(`${environment.API_URL}/${this.controllerName}/profile/get`,ob);
    }
}
